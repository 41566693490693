import React from "react";
import Texts from "../../../utils/lang-utils";
import spinner from "../../main/assets/images/resources/loader25.gif";

const OnboardingModal = (props) => {
  return (
    <div
      className="modal fade"
      id="onboardingImportMenu"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="onboardingModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="ugf-form-card modal-body">
            <h3 className="modal-body-info">{Texts.onboarding.stepMenuImportMenu}</h3>
            <div className="modal-body-content account-category">
              {props.spreadsheetId ? (
                <iframe
                  src={`https://docs.google.com/spreadsheets/d/${props.spreadsheetId}/edit?usp=sharing?widget=true&amp;headers=false`}
                  style={{ width: "100%", height: "600px" }}
                ></iframe>
              ) : (
                <div className="modal-spinner">
                  <div className="spinner">
                    <img src={spinner}></img>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button data-test="btn-cancel" className="btn-cancel" type="button" data-dismiss="modal" aria-label="Close">
              {Texts.onboarding.cancel}
            </button>
            <button data-test="btn-apply" className="btn-apply" type="button" onClick={props.onFinish} data-dismiss="modal" aria-label="Submit">
              {Texts.onboarding.continue}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;
