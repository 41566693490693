import React from "react";
import Texts from "../../../utils/lang-utils";

// import mocImage from "../../main/assets/images/resources/japan.png";
const OnboardingModalItem = (props) => {
  return (
    <button className="content-item" type="button" onClick={props.onClick} data-dismiss="modal" aria-label="Close" data-test={`menu-${props.titleKey}`}> 
      <div className="element">
        <div className={`country`} style={{ backgroundImage: `url(${props.src})`  }}>
          <span className="content-item-name">{props.name}</span>
          <div className="content-select">
            <i className="las la-arrow-right"></i>
          </div>
        </div>
      </div>
    </button>
  );
};

export default OnboardingModalItem;
