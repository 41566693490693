import React from "react";
import Texts from "../../../utils/lang-utils";

const OnboardingStepName = (props) => {
  return (
    <div className="ugf-wrapper onboarding-content">
      <div className="ugf-content-block onboarding-content-wrapper">
        <div className="ugf-content menu-content onboarding-content-wrapper-name">
          <h1 dangerouslySetInnerHTML={{ __html: Texts.onboarding.stepNameTitle }}></h1>
          <p>{Texts.onboarding.stepNameDescription}</p>
          <form
            onSubmit={(e) => props.onNext({ restaurantName: e.target.elements[0].value })}
            className="form-flex email-form"
          >
            <div className="form-group-ax">
              <input type="text" placeholder="Sunset View Cafe" className="form-control" required data-test="start-input" />
            </div>
            <div className="form-group-ax">
              <button className="btn" data-test="start-button">
              {Texts.onboarding.start} <i className="las la-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OnboardingStepName;
