import React  from "react";
import OnboardingModalItem from "./OnboardingModalItem";
import Texts from "../../../utils/lang-utils";
import { config } from "../../../utils/config";

const OnboardingModal = (props) => {
  return (
    <div
      className="modal fade"
      id="onboardingModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="onboardingModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="ugf-form-card modal-body">
            <h3 className="modal-body-info">{Texts.onboarding.stepMenuSampleMenu}</h3>
            <div className="modal-body-content account-category">
              {config.MenuTemplates.map((t, key) => <OnboardingModalItem key={key} onClick={() => props.onSelect(t.spreadsheetId)} name={Texts.onboarding[t.titleKey]} src={t.image} titleKey={t.titleKey}></OnboardingModalItem>)}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingModal;
