import React, { Component } from "react";
import { cognito } from "../../../utils/cognito";
import Texts from "../../../utils/lang-utils";
import google from "../../main/assets/images/resources/google.png";
import spinner from "../../main/assets/images/resources/load.gif";

export default class OnboardingStepEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordErrorMessage: "",
      loginErrorMessage: "",
      restaurantName: props.restaurantName,
      onNext: props.onNext,
      errorModal: true,
      isLoading: false
    };
  }

  signUpWithGoogle = (e) => {
    e.preventDefault();
    cognito.signupWithGoogle();
  };

  validatePassword = async (pass) => {
    if (pass.length < 8) {
      throw new Error(Texts.onboarding.PassLength);
    }
    if (!pass.match('(?=.*[a-z])')) {
      throw new Error(Texts.onboarding.PassLowercase);
    }
  }

  signUpWithPassword = async (e) => {
    e.preventDefault();
    this.setState({ passwordErrorMessage: null, loginErrorMessage: null, isLoading: true });
    const form = e.currentTarget;
    const login = form.elements[0].value;
    const pass = form.elements[1].value;
    try {
      await this.validatePassword(pass);
      const token = await cognito.signupWithPassword(login, pass).catch((a) => {
        if (a === "An account with the given email already exists.") {
          this.setState({ loginErrorMessage: a, isLoading: false });
        } else {
          this.setState({ passwordErrorMessage: a, isLoading: false });
        }
      });
      if (token) {
        this.state.onNext({ token });
      }
    } catch(e) {
      this.setState({ passwordErrorMessage: e.message, isLoading: false });
    }

  };

  render() {
    return (
      <div className="ugf-wrapper onboarding-content">
        <div className="ugf-content-block onboarding-content-wrapper">
          <div className="ugf-content menu-content onboarding-content-wrapper-name">
            <h1 dangerouslySetInnerHTML={{ __html: Texts.onboarding.stepEmailTitle }}></h1>
            <p>{Texts.onboarding.stepEmailDescription}</p>
            <form className="form-flex email-form" onSubmit={this.signUpWithPassword}>
              <div className="form-group-ax">
                <div className="form-group-ax-container">
                  <span className="description">{Texts.onboarding.Email}</span>
                  <input
                    type="email"
                    placeholder="you@example.com"
                    data-test="email-input"
                    className={`form-control password-input ${
                      this.state.loginErrorMessage ? "password-input-error" : ""
                    }`}
                    required
                  />
                  {this.state.loginErrorMessage && (
                    <span className="password-error">{this.state.loginErrorMessage}</span>
                  )}
                </div>
                <div className="form-group-ax-container">
                  <span className="description">{Texts.onboarding.Password}</span>
                  <input
                    type="password"
                    data-test="password-input"
                    placeholder={Texts.onboarding.characters}
                    className={`form-control password-input ${
                      this.state.passwordErrorMessage ? "password-input-error" : ""
                    }`}
                    required
                  />
                  {this.state.passwordErrorMessage && (
                    <span className="password-error">{this.state.passwordErrorMessage}</span>
                  )}
                </div>
              </div>
              <div className="form-group-ax">
                <button className="btn" type="submit" disabled={this.state.isLoading} data-test="finish-button">
                  {Texts.onboarding.finish} 
                  <i className="las la-arrow-right"></i> 
                  {this.state.isLoading && <img src={spinner} className="load-spinner"></img>}
                </button>
                <h3 className="log-variant">
                  <p>{Texts.onboarding.or}</p>
                </h3>
                <button data-test="google-finish-button" className="btn-submit" type="button" onClick={this.signUpWithGoogle} disabled={this.state.isLoading}>
                  <img src={google} alt="Google" className="blue" />
                  <span>{Texts.onboarding.continueWithGoogle}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
