import React, { useState } from "react";
import Texts from "../../../utils/lang-utils";
import OnboardingModal from "./OnboardingModal";
import OnboardingImportMenu from "./OnboardingImportMenu";
import { config } from "../../../utils/config";
import { xhr } from "../../../utils/window-utils";

const OnboardingStepMenu = (props) => {
  const [spreadsheetId, setSpreadsheetId] = useState(null);
  const createMenuSpreadSheet = () => {
    if (spreadsheetId) return;
    xhr(config.MenuApiUrl, {
      method: "POST",
      body: JSON.stringify({
        query: `mutation ($restaurantName: String!) {createMenuSpreadSheet(restaurantName: $restaurantName)}`,
        variables: { restaurantName: props.restaurantName },
      }),
      headers: new Headers({ "x-api-key": config.MenuApiKey, "Content-Type": "application/json" }),
    }).then((r) => {
      r.json().then((data) => setSpreadsheetId(data.data.createMenuSpreadSheet));
    });
  };

  return (
    <div className="ugf-wrapper onboarding-content">
      <div className="ugf-content-block onboarding-content-wrapper">
        <div className="ugf-content menu-content onboarding-content-wrapper-name">
          <h2>{Texts.onboarding.stepMenuTitle}</h2>
          <p>{Texts.onboarding.stepMenuDescription}</p>
          <div className="account-category">
            <a
              href="#"
              className="account-type personal"
              data-toggle="modal"
              data-target="#onboardingModal"
            >
              <span className="account-icon">
                <i className="las la-utensils menu-type"></i>
              </span>
              <p>{Texts.onboarding.stepMenuSampleMenu}</p>
              <span className="icon">
                <i className="las la-arrow-right"></i>
              </span>
            </a>
            <a
              data-toggle="modal"
              onClick={() => createMenuSpreadSheet()}
              data-target="#onboardingImportMenu"
              href="#"
              className="account-type business"
            >
              <span className="account-icon">
                <i className="las la-file-excel menu-type"></i>
              </span>
              <p>{Texts.onboarding.stepMenuImportMenu}</p>
              <span className="icon">
                <i className="las la-arrow-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <OnboardingModal onSelect={(spreadsheetId) => {
        props.onNext({ spreadsheetId })
      }}></OnboardingModal>
      <OnboardingImportMenu
        spreadsheetId={spreadsheetId}
        onFinish={() => props.onNext({ spreadsheetId })}
      ></OnboardingImportMenu>
    </div>
  );
};

export default OnboardingStepMenu;
