import React, { Component } from "react";
import Helmet from "react-helmet";
import Texts from "../../../utils/lang-utils";
import OnboardingStepName from "./OnboardingStepName";
import OnboardingStepMenu from "./OnboardingStepMenu";
import OnboardingStepEmail from "./OnboardingStepEmail";
import OnboardingComplete from "../components/OnboardingComplete";
import logoDark from "../../main/assets/images/resources/logo-long-text.svg";
import { config } from "../../../utils/config";

export default class OnboardingWizard extends Component {
  state = {
    currentStep: 0,
    sampleMenuId: null,
    restaurantName: "",
  };

  onNext = (data) => {
    this.setState({ currentStep: ++this.state.currentStep, ...data }, () =>
      localStorage.setItem("join-state", JSON.stringify(this.state))
    );
  };
  onPrevious = () => {
    this.setState({ currentStep: 2 }, () => localStorage.setItem("join-state", JSON.stringify(this.state)));
  };

  steps = [
    () => <OnboardingStepName onNext={this.onNext}></OnboardingStepName>,
    () => <OnboardingStepMenu restaurantName={this.state.restaurantName} onNext={this.onNext}></OnboardingStepMenu>,
    () => <OnboardingStepEmail restaurantName={this.state.restaurantName} onNext={this.onNext}></OnboardingStepEmail>,
    () => <OnboardingComplete token={this.state.token} onPrevious={this.onPrevious}></OnboardingComplete>,
  ];

  stepsDescription = [Texts.onboarding.stepNameNav, Texts.onboarding.stepMenuNav, Texts.onboarding.stepEmailNav];

  render() {
    return (
      <>
        <Helmet htmlAttributes={{ lang: this.props.language || "en" }}>
          <script src="/onboarding/assets/js/jquery.min.js"></script>
          <script src="/onboarding/assets/js/bootstrap.min.js"></script>
          <title>{Texts.onboarding.pageTitle}</title>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
          <meta name="description" content={Texts.onboarding.description} />
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
          <link rel="stylesheet" type="text/css" href="/onboarding/assets/css/bootstrap.min.css" />
          <link rel="stylesheet" type="text/css" href="/onboarding/assets/css/line-awesome.min.css" />
          <link rel="stylesheet" type="text/css" href="/onboarding/css/main.css" />
          <link rel="stylesheet" type="text/css" href="/onboarding/css/theme-1.css" />
          <script src="https://cdn.jsdelivr.net/npm/amazon-cognito-identity-js@5.0.3/dist/amazon-cognito-identity.min.js"></script>
        </Helmet>
        {this.state && (
          <div className={`ugf-wrapper onboarding theme-bg step-${this.state.currentStep}`}>
            <div className="onboarding-left">
              <div className="onboarding-left-logo">
                <a href="#">
                  <img src={logoDark} alt="Eatellia" />
                </a>
              </div>
              {this.state.currentStep === 0 && (
                <div className="alternet-access">
                  <p data-test="start-login-now">
                    {Texts.onboarding.alreadyRegistered}{" "}
                    <a href={config.AdminUrl}>{Texts.onboarding.login}</a>
                  </p>
                </div>
              )}
              {!!this.state.currentStep && (
                <div className="ugf-sidebar flex-bottom ugf-sidebar-bg-3 sidebar-steps">
                  <div className="steps" data-test="start-steps">
                    {this.stepsDescription.map((description, key) => (
                      <div
                        key={key}
                        data-test={`start-step-${key+1}`}
                        className={`step${this.state.currentStep > key ? " complete-step" : ""}${
                          this.state.currentStep === key ? " step-onprocess" : ""
                        }`}
                      >
                        <span>{key + 1}</span>
                        <a
                          className={`${key < this.state.currentStep ? "step-navigate" : "step-default"}`}
                          onClick={() =>
                            this.setState(() => ({
                              currentStep: key > this.state.currentStep ? this.state.currentStep : key,
                            }))
                          }
                        >
                          {description}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="onboarding-right">{this.steps[this.state.currentStep]()}</div>
          </div>
        )}
      </>
    );
  }
}
